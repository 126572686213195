import * as React from 'react'

import { graphql } from 'gatsby'
import Parser from 'html-react-parser'
import { withTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Cta from '../components/Cta'
import SearchCard from '../components/SearchCard'
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Container,
  FormControl,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'

// Images
import LoadingGif from '../assets/images/loading.svg'
import CloseSVG from '../assets/images/close.svg'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ApparelIcon from '../assets/images/svg/apparel.inline.svg'
import FabricIcon from '../assets/images/svg/fabric.inline.svg'
import YarnsIcon from '../assets/images/svg/yarns.inline.svg'
import TrimsIcon from '../assets/images/svg/trims.inline.svg'
import HomeTextilesIcon from '../assets/images/svg/home-textiles.inline.svg'

//Content
import { ctaContent } from '../content/cta'

// Styling
import '../styles/search.scss'

const api = process.env.API

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const products = {
  apparel: { icon: <ApparelIcon />, color: 'silkyGreen', label: 'Aparel', defaultId: 1, newCategory: false },
  fabrics: { icon: <FabricIcon />, color: 'indigo', label: 'Fabrics', defaultId: 2, newCategory: false },
  trims: { icon: <TrimsIcon />, color: 'darkBlue', label: 'Trims', defaultId: 3, newCategory: false },
  yarns: { icon: <YarnsIcon />, color: 'tourquise', label: 'Yarns', defaultId: 4, newCategory: false },
  hometextiles: {
    icon: <HomeTextilesIcon />,
    color: 'mysticIris',
    label: 'Home Textiles',
    defaultId: 5,
    newCategory: true,
  },
}

const MiniChip = styled(Chip)(({ theme }) => ({
  '&&': {
    height: 24,
    '&:hover': {
      backgroundColor: theme.palette.silkyGreen.main,
    },
    '.MuiChip-label': {
      fontSize: '12px',
      color: '#ffffff',
    },
  },
}))

const newCategoryProps = {
  deleteIcon: <MiniChip label='New' color='silkyGreen' size='small' />,
  onDelete: () => () => {},
}

class SearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.t = props.t
    const urlType = new URLSearchParams(this.props.location.search).get('type')

    this.state = {
      results: [],
      productCategories: [],
      countries: [],
      type: products[urlType]?.defaultId ?? null,
      page: 1,
      showMore: true,
      selectedCountries: [],
      loading: true,
      loadingBtn: false,
    }
  }

  componentDidMount() {
    this.fetchResults(this.state.page)
  }

  render() {
    return (
      <Layout headerWhite={true} activePage={'Explore Network'}>
        <Toolbar />
        <Box sx={{ mt: { xs: 6, md: 8 }, mb: 6 }}>
          <Container disableGutters maxWidth='xl' sx={{ textAlign: 'center' }}>
            <Typography variant='h1'>{Parser(this.t('SEARCH_TITLE'))}</Typography>
            <Typography component='p' sx={{ my: 6 }}>
              {Parser(this.t('SEARCH_SUBTITLE'))}
            </Typography>
            <Box className='filters-container' sx={{ my: 6 }}>
              <Stack direction='row' justifyContent='center' sx={{ flexWrap: 'wrap', gap: 1, width: '100%', my: 2 }}>
                {this.state.productCategories?.map((key, id) => {
                  const productCategory = key.value.replace('-', '')
                  return (
                    <Chip
                      key={id}
                      icon={products[productCategory]?.icon}
                      id={productCategory + '-chip'}
                      className={'chip-' + productCategory + ' ' + (key.id !== this.state.type ? 'disabled' : 'active')}
                      label={key.name}
                      color={products[productCategory]?.color}
                      onClick={() => {
                        this.changeType(key.id)
                      }}
                      sx={{ order: products[productCategory]?.defaultId }}
                      {...(products[productCategory]?.newCategory && newCategoryProps)}
                    />
                  )
                })}
                <Box className='countries'>
                  <FormControl id='select-container' disabled={this.state.countries?.length === 0}>
                    <InputLabel id='demo-multiple-checkbox-label'>{this.t('COUNTRIES')}</InputLabel>
                    <Select
                      multiple
                      variant='outlined'
                      value={this.state.selectedCountries}
                      onChange={this.changeCountry}
                      input={<OutlinedInput label={this.t('COUNTRIES')} />}
                      renderValue={() => {
                        return this.state.selectedCountries?.length + this.t('SELECTED_CONTRIES')
                      }}
                      MenuProps={MenuProps}
                    >
                      {this.state.countries?.map((country) => (
                        <MenuItem key={country.value} value={country.id} name={country.value}>
                          <Checkbox checked={this.state.selectedCountries?.indexOf(country.id) > -1} />
                          <ListItemText primary={country.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              {this.state.selectedCountries?.length > 0 && (
                <div className='selected-countries d-flex align-items-center flex-wrap'>
                  <h5 className='flex-fill'>{this.t('SELECTED_FILTERS')}</h5>
                  <div className='badges d-flex align-items-center flex-wrap'>
                    {this.state.selectedCountries?.map((country, key) => (
                      <div className='country-badge d-flex align-items-center' key={key}>
                        <span className='name'>{this.state.countries?.find((tmp) => tmp.id === country)?.name}</span>
                        <div
                          className='d-flex align-items-center'
                          role='button'
                          tabindex={0}
                          onClick={() => {
                            this.removeCountry(country)
                          }}
                          onKeyDown={() => {
                            this.removeCountry(country)
                          }}
                        >
                          <img className='ps-s-8' src={CloseSVG} height={15} alt='Close' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Box>
            <Alert icon={<InfoOutlinedIcon />} sx={{ textAlign: 'left' }} severity='success'>
              <Typography variant='body2Bold' component='p'>
                {Parser(this.t('LOOKING_FOR'))}
              </Typography>
              <Link
                underline='hover'
                rel='noopener'
                href={`${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/auth`}
              >
                {Parser(this.t('LOOKING_FOR_TEXT'))}
              </Link>
            </Alert>
          </Container>
        </Box>
        <Container disableGutters maxWidth='xl' sx={{ textAlign: 'center' }} className='search-container'>
          {this.state.loading ? (
            <Box>
              <img src={LoadingGif} height={100} alt={'Loading...'} />
            </Box>
          ) : (
            <>
              <Box className='row gx-4 gy-4'>
                {this.state.results?.map((infoCard, i) => (
                  <Box key={i} className='col-13 col-sm-6 col-md-4 col-xl-3'>
                    <SearchCard info={infoCard} type={this.state.type} />
                  </Box>
                ))}
              </Box>
              {this.state.showMore && (
                <Box sx={{ my: { xs: 6, md: 8 } }}>
                  <button
                    className='btn btn-primary btn-md'
                    onClick={() => {
                      this.showMore()
                    }}
                    disabled={this.state.loadingBtn}
                  >
                    {this.state.loadingBtn ? this.t('SEARCH_LOADING') : this.t('SEARCH_SHOW_MORE')}
                  </button>
                </Box>
              )}
            </>
          )}
        </Container>
        <Cta content={ctaContent} />
      </Layout>
    )
  }

  showMore = () => {
    this.setState({ page: this.state.page + 1, loadingBtn: true }, function () {
      this.fetchResults(this.state.page)
    })
  }

  changeType = (newType) => {
    this.setState(
      {
        page: 1,
        results: [],
        type: this.state.type === newType ? null : newType,
        loading: true,
        selectedCountries: [],
        countries: [],
      },
      function () {
        this.fetchResults()
      }
    )
  }

  changeCountry = (event) => {
    this.setState({ selectedCountries: event.target.value, results: [], loading: true }, function () {
      this.fetchResults()
    })
  }

  removeCountry = (id) => {
    const newCountries = this.state.selectedCountries.filter((e) => e !== id)
    this.setState({ selectedCountries: newCountries, results: [], loading: true }, function () {
      this.fetchResults()
    })
  }

  fetchResults = (pageNumber) => {
    let headers = new Headers()
    headers.set('Domain', 'public')

    fetch(api + `search/entities?page=${pageNumber ?? 1}&limit=20&generic=true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([
        { key: 'entity_country', selected: this.state.selectedCountries ?? [] },
        { key: 'product_category', selected: this.state.type ? [this.state.type] : [] },
      ]),
    })
      .then((response) => response.json())
      .then((resultData) => {
        let currentArray = [...this.state.results]
        resultData?.data?.results?.forEach((result) => {
          currentArray.push(result)
        })

        this.setState({
          results: currentArray,
          showMore: resultData?.links?.next ? true : false,
          loading: false,
          loadingBtn: false,
          countries: resultData?.data?.facets
            ?.find((obj) => obj.key === 'entity_country')
            ['values'].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
          productCategories: resultData?.data?.facets?.find((obj) => obj.key === 'product_category')['values'],
        })
      })
  }
}

export const Head = ({ location }) => (
  <Seo
    title='Explore Network'
    description='Discover Suppliers In The Network. Target your search based on certificates, product groups, fabric types, target groups, MOQs and more.'
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withTranslation()(SearchPage)
